import React, { useEffect } from 'react';

const AdComponent = () => {
  useEffect(() => {
    const script1 = document.createElement('script');
    script1.type = 'text/javascript';
    script1.innerHTML = `
      atOptions = {
        'key' : '1ef3738e379df1e43552c9abf0a51c4f',
        'format' : 'iframe',
        'height' : 60,
        'width' : 468,
        'params' : {}
      };
    `;
    
    const script2 = document.createElement('script');
    script2.type = 'text/javascript';
    script2.src = '//www.topcreativeformat.com/1ef3738e379df1e43552c9abf0a51c4f/invoke.js';
    
    document.body.appendChild(script1);
    document.body.appendChild(script2);

    // Clean up the scripts on component unmount
    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
    };
  }, []);

  return (
    <div>
      {/* Optionally, you can add a placeholder for the ad */}
      <div id="ad-container" style={{ width: '468px', height: '60px' }}></div>
    </div>
  );
};

export default AdComponent;