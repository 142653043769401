/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import axios from 'axios';  
import {  Link } from "react-router-dom";

import { Helmet } from "react-helmet";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SoftInput from "components/SoftInput";
import Box from '@mui/material/Box';

// @mui material components
import Card from "@mui/material/Card";

import Grid from "@mui/material/Grid";

import Parser from 'html-react-parser';
import SoftButton from "components/SoftButton";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import team2 from "assets/images/team-2.jpg";

//import Modal from 'react-bootstrap/Modal';

// Data
//import membersTableData from "layouts/groups/data/membersTableData";

import SoftAvatar from "components/SoftAvatar";
import AdComponent from "layouts/liveauction/common/AdComponent";
// Images
import 'auction.css';

import React, { useState, useEffect } from "react";
import FormSelect from 'react-bootstrap/FormSelect';
import { FaBackward } from "react-icons/fa";
import { FaPenSquare } from "react-icons/fa";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    width:'60%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {zIndex: 100000}   
};



const data = [
    { option: "0", style: { backgroundColor: "green", textColor: "white" } },
    { option: "1", style: { backgroundColor: "red", textColor: "white" } },
    { option: "3", style: { backgroundColor: "blue", textColor: "white" } },
    { option: "4", style: { backgroundColor: "pink", textColor: "white" } },
    { option: "5", style: { backgroundColor: "orrange", textColor: "white" } },
    { option: "6", style: { backgroundColor: "gray", textColor: "white" } },
    { option: "7", style: { backgroundColor: "black", textColor: "white" } },
    { option: "8", style: { backgroundColor: "red", textColor: "white" } }
    
  ];

  

  function UnsoldPlayer({ user_id, player_name, sl_no, profile_pic, cric_heroes_profile_link, noGutter }) {
    return (
      <SoftBox
        mb={noGutter ? 0 : 1}
      >
            <SoftTypography variant="caption" color="text">
              <SoftTypography variant="caption" color="info" fontWeight="medium" textTransform="capitalize">
              {sl_no}. {player_name} 
              </SoftTypography>
              &nbsp;
              
            </SoftTypography>
       
      </SoftBox>
    );
  }
  function PendingPlayer({ user_id, player_name, sl_no, profile_pic, cric_heroes_profile_link, auction_sold_status,noGutter }) {
    return (
      <SoftBox
        mb={noGutter ? 0 : 1}
       
      >
              <SoftTypography variant="caption" color="info" fontWeight="medium" textTransform="capitalize">
              {sl_no}. {player_name} 


              {
        //last_sold_status_flag

        (() => {
          if (auction_sold_status == 2) {
            return (<span style={{ color: 'red' }}>*</span>)
          }
        })()

      }

              
              </SoftTypography>
      </SoftBox>
    );
  }

/*function Team({ team_name, team_logo, current_wallet_balance, current_players_count, players,max_bid_for_a_player, noGutter,linktoteam , max_wallet_size }) {
  
  var linktoteam1 = '/teamplayers';
  var qrystring = '?t='+linktoteam;

  return (
    <Grid xs={12} md={12} style={{border: '1px solid rgba(0, 0, 0, 0.05)'}}>
       
       <SoftBox display="flex"  p={1}>
          <SoftAvatar
            src={team_logo ? (team_logo) : (team2)}
            alt="profile-image"
            variant="rounded"
            size="sm"
            shadow="sm"
          />
  &nbsp;
  <Link to={{pathname:linktoteam1,search:qrystring} }>
     
            {team_name}
          </Link>

          </SoftBox>
         
          <SoftBox mb={1} lineHeight={0}>
          <SoftTypography variant="caption" color="text">
            Maximum Wallet :&nbsp;&nbsp;&nbsp;
            <SoftTypography variant="caption" fontWeight="medium">
              {max_wallet_size}
            </SoftTypography>
          </SoftTypography>
        </SoftBox>

        <SoftBox mb={1} lineHeight={0}>
          <SoftTypography variant="caption" color="text">
            Current Wallet Balance:&nbsp;&nbsp;&nbsp;
            <SoftTypography variant="caption" fontWeight="medium">
              {current_wallet_balance}
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
        
        <SoftBox mb={1} lineHeight={0}>
        <SoftTypography variant="caption" color="text">
          Maximum bid for a player:&nbsp;&nbsp;&nbsp;
          <SoftTypography variant="caption" fontWeight="medium">
            {max_bid_for_a_player}
          </SoftTypography>
        </SoftTypography>
        </SoftBox>

        <SoftBox mb={1} lineHeight={0}>
        <SoftTypography variant="caption" color="text">
          Current Count:&nbsp;&nbsp;&nbsp;
          <SoftTypography variant="caption" fontWeight="medium">
            {current_players_count}
          </SoftTypography>
          
           </SoftTypography>
           </SoftBox>
           <SoftTypography>

{
      (() => {
      const listitems = players;
      //console.log(listitems);
      //console.log("populating")
      if (typeof(listitems) != "undefined")
      {
      //console.log(listitems)
  const rows = [];
  for (let i = 0; i < listitems.length; i++) 
  {
    let captain_flag_name = "";
    //team_name,  team_logo, current_wallet_balance, current_players_count, players
      if(listitems[i].captain_flag=='1')
      {
        captain_flag_name = " (C)";
      }
      rows.push(<SoftTypography size="sm" style={{ fontSize: 14 }}>{i+1}. {listitems[i].full_name}{captain_flag_name}&nbsp;[{listitems[i].sl_no}]&nbsp;[{listitems[i].auction_sold_amount}]
      
      {
        //last_sold_status_flag

        (() => {
          if (listitems[i].last_sold_status_flag == 1) {
            return (<span style={{ color: 'red' }}>***</span>)
          }
        })()

      }
      {
                // alert(captain_flag)
                (() => {
                  if (listitems[i].captain_flag == 0) {
                    return null
                  } else {
                    return (<span>&nbsp;(c)</span>)
                  }
                })()
              }
      
      </SoftTypography>);
  }
  return rows;
}

})()

}
</SoftTypography>

    </Grid>
  );
}
*/
function Team({ team_name, team_logo, current_wallet_balance, current_players_count, players, max_bid_for_a_player, noGutter, linktoteam, max_wallet_size,max_players_count }) {

  var linktoteam1 = '/teamplayers';
  var qrystring = '?t=' + linktoteam;

  return (
    <Grid xs={12} md={12} style={{ border: '1px solid rgba(0, 0, 0, 0.05)' ,backgroundColor: '#fffffe', // Light hash color
    padding: '10px',
    borderRadius: '8px',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
    transition: 'transform 0.3s',
    ':hover': {
      backgroundColor: '#b2ebf2', // Darker hash color on hover
      transform: 'scale(1.05)',
    },
    
    }}>

      <SoftBox display="flex" p={1}>
        <SoftAvatar
          src={team_logo ? (team_logo) : (team2)}
          alt="profile-image"
          variant="rounded"
          size="sm"
          shadow="sm"
        />
        &nbsp;
        <Link to={{ pathname: linktoteam1, search: qrystring }}>
          {team_name}
        </Link>
      </SoftBox>

      <SoftBox mb={3}>


      <table style={{ width: "100%", fontSize: "14px" }} border="0" cellPadding="3" cellSpacing="3">
                  <tbody>
                    <tr>
                      <td style={{  padding: "4px",   paddingBottom : "5px" , wordBreak : "break-all" }}>Current Wallet Balance</td>
                      <td style={{  padding: "4px",   paddingBottom : "5px" }}>:</td>
                      <td style={{  padding: "4px",   paddingBottom : "5px" }}>{current_wallet_balance}</td>
                      <td style={{  padding: "4px",  paddingBottom : "5px"  , wordBreak : "break-all" }}>Maximum Wallet </td>
                      <td style={{  padding: "4px",   paddingBottom : "5px" }}>:</td>
                      <td style={{  padding: "4px",   paddingBottom : "5px" }}>{max_wallet_size}</td>
                      
                    </tr>

                    <tr>
                      <td style={{  padding: "4px",   paddingBottom : "5px" , wordBreak : "break-all"  }}>Maximum bid for a player</td>
                      <td style={{  padding: "4px",   paddingBottom : "5px" }}>:</td>
                      <td style={{  padding: "4px",   paddingBottom : "5px" , color : "red" }}>{max_bid_for_a_player}</td>
                      <td style={{  padding: "4px",   paddingBottom : "5px" , wordBreak : "break-all"  }}>Current Count</td>
                      <td style={{  padding: "4px",   paddingBottom : "5px" }}>:</td>
                      <td style={{  padding: "4px",   paddingBottom : "5px" }}>{current_players_count}/{max_players_count}</td>
                      
                    </tr>

                  </tbody>
        </table>
        </SoftBox>
 
    {/* 
          <SoftTypography variant="caption" color="text">
          Maximum Wallet :&nbsp;&nbsp;&nbsp;
          <SoftTypography variant="caption" fontWeight="medium">
            {max_wallet_size}
          </SoftTypography>
        </SoftTypography>

<SoftBox mb={1} lineHeight={0}>
        <SoftTypography variant="caption" color="text">
          Current Wallet Balance:&nbsp;&nbsp;&nbsp;
          <SoftTypography variant="caption" fontWeight="medium">
            {current_wallet_balance}
          </SoftTypography>
        </SoftTypography>
      </SoftBox>

      <SoftBox mb={1} lineHeight={0}>
        <SoftTypography variant="caption" color="text">
          Maximum bid for a player:&nbsp;&nbsp;&nbsp;
          <SoftTypography variant="caption" fontWeight="medium">
            {max_bid_for_a_player}
          </SoftTypography>
        </SoftTypography>
      </SoftBox>

      <SoftBox mb={1} lineHeight={0}>
        <SoftTypography variant="caption" color="text">
          Current Count:&nbsp;&nbsp;&nbsp;
          <SoftTypography variant="caption" fontWeight="medium">
            {current_players_count}
          </SoftTypography>
        </SoftTypography>
      </SoftBox>
    */}
      <SoftTypography>
        {
          (() => {
            const listitems = players;
            if (typeof (listitems) != "undefined") {
              return (
                <table style={{ width: "100%", borderCollapse: "collapse", fontSize: "12px" }}>
                  <thead>
                    <tr>
                      <th style={{ border: "1px solid black", padding: "4px" }}>#</th>
                      <th style={{ border: "1px solid black", padding: "4px" }}>Name</th>
                      <th style={{ border: "1px solid black", padding: "4px" }}>Auction Sold Amount</th>
                      <th style={{ border: "1px solid black", padding: "4px" }}>PDF Sl No</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    {listitems.map((player, index) => (
                      <tr key={index}>
                        <td style={{ border: "1px solid black", padding: "4px" }}>{index + 1}</td>
                        <td style={{ border: "1px solid black", padding: "4px" }}>{player.full_name} {player.captain_flag == '1' ? '(C)' : ''} {player.last_sold_status_flag == 1 ? <span style={{ color: 'red' }}>***</span> : ''}</td>
                        <td style={{ border: "1px solid black", padding: "4px" }}>{player.auction_sold_amount}</td>
                        <td style={{ border: "1px solid black", padding: "4px" }}>{player.sl_no}</td>
                        
                      </tr>
                    ))}
                  </tbody>
                </table>
              );
            }
          })()
        }
      </SoftTypography>
    </Grid>
  );
}



class LiveAuction extends React.Component {
  constructor(props) {
    super(props);
        this.state = { x: false,sx: false,main_auction_details:{group_name:'Group Name'},spin_data:data,counter:0};
    window.helloComponent = this;
//    this.setState({x: false,sx: false});

  }

 onEditSellModal  = (sl_no,player_name,team_id,sold_amount,user_id,e)=> 
 {
    this.setState({showEditSell: !this.state.showEditSell,
    e_selected_sl:sl_no,
    e_selected_player:player_name,
    e_team_id:team_id,
    e_sold_amount:sold_amount});

    setTimeout(
        () =>     document.getElementById('e_a_match_team_id').value = team_id, 
        700
    );

   
 };

  //const [x, sx] = React.useState(false);

  stopSpin = e => {
    console.log("stoppppppppp", e);
    //sx(false);
    //alert(this.state.spin_data[this.state.prizeNo].player_name)

    //this.setState({optSmModal:false})

    this.setState({x: false,prizeNo : 1,showSell: !this.state.showSell,
    selected_sl:this.state.spin_data[this.state.prizeNo].option,
    selected_player:this.state.spin_data[this.state.prizeNo].player_name});
    this.showSellModal(this.state.spin_data[this.state.prizeNo].option,this.state.spin_data[this.state.prizeNo].player_name,this.state.spin_data[this.state.prizeNo].player_id,this.state.spin_data[this.state.prizeNo].player_pic,this.state.spin_data[this.state.prizeNo].player_cric_link);
 
  };

 showSellModal (random_sl,player_name,player_id,player_pic,player_cric_link) {

  let search = window.location.search;
    let params = new URLSearchParams(search);
    let series_id = params.get('i');
  //alert(random_sl)
//    document.getElementById('i_sell_modal_html').innerHTML = "<h3>"+random_sl+"</h3>";


  //getPlayerProfile(group_fk,user_fk,'i_profile_modal',invite_code);
  //call API i_profile_modal
  //alert(group_fk + ' ' + user_fk)
  /*
  this.setState({
    show: !this.state.show
  });
  */
};

  closeshowSell = e => {
    this.setState({
      showSell: false
    });


  
};


  closeEditshowSell = e => {
    this.setState({
      showEditSell: false,
      counter: 0
    });


  
};


  componentDidMount() {
    this.callbasicapi();

    /*
    this.interval = setInterval(() => {
      if (this.state.counter < 20) {
        this.callbasicapi();
        
      } else {
        clearInterval(this.interval);
      }
    }, 10000); // 60000 milliseconds = 1 minute
    */
  }
  componentWillUnmount() {
    //clearInterval(this.interval);
  }




  callbasicapi()
  {
    let search = window.location.search;
      let params = new URLSearchParams(search);
      let invite_code = params.get('i');

    
      const post_data = { invite_code: invite_code };
      const headers = {
      'Content-Type': 'application/json'
      }
      
    axios.post( global.config.app.backend_api_url + `auction/get_current_auction_status_by_invite_code/`,post_data, {headers: headers, withCredentials: true }
    )  
      .then(res => {  
        //const normalmembers = res.data;  
        console.log("after112d")
        var status  = res.data.status;
        var message  = res.data.message;
        if(status==0)
        {
          console.log(message);
          //return
        }
        var auction_details  = res.data.auction_details;
        var main_auction_details  = auction_details.main[0];
        console.log(main_auction_details)
        var teams_details  = auction_details.teams;
        var sold_players  = auction_details.sold;
        var pending_players  = auction_details.pending;
        
        
        
        this.setState( {counter : this.state.counter + 1, main_auction_details: main_auction_details,teams_details:teams_details,sold_players:sold_players,
        pending_players:pending_players});

      });
   
  }
    

  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    return (

      <DashboardLayout>
      <Helmet>
      <title>Auction | {global.config.app.name}</title>
      <meta
        name="description"
    content="Join Our Cricket Team - Experience the thrill of competitive cricket and be part of a supportive community. Sign up now and showcase your skills on the field."
      />
      <meta
        name="keywords"
        content="Cricket, team, join, competitive, skills, community, sign up, field"
      />
    </Helmet>
      <DashboardNavbar />
      <Card>

      <SoftBox my={3}>
          <Grid container spacing={3}>
            
<Grid item xs={12} md={12}>
   <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={1}>
    <table width="100%">
    <tbody>
    <tr>
    <td colSpan='4' style={{color:'red'}}>{this.state.main_auction_details.group_name} - {this.state.main_auction_details.series_name}</td>
    </tr>
    <tr>
                        <td>
                          No. of Teams:</td>
                        <td>
                          {this.state.main_auction_details.no_of_teams}
                        </td>
                        <td>
                          Players in auction:</td>
                        <td>
                          {this.state.main_auction_details.count_players_in_auction}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Team wallet size:</td>
                        <td>
                          {this.state.main_auction_details.team_max_pocket_size}
                        </td>
                        <td>
                          Player base price:</td>
                        <td>
                          {this.state.main_auction_details.player_base_price}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Pending players:</td>
                        <td>
                          {this.state.main_auction_details.pending_count}
                        </td>
                        <td>
                          Unsold players:</td>
                        <td>
                          {this.state.main_auction_details.unsold_count}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Sold players:</td>
                        <td>
                          {this.state.main_auction_details.sold_count}
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
    
     
    </tbody>
    </table>

    </SoftBox>

<SoftBox display="flex" justifyContent="space-between" pb={0} alignItems="center" p={1}>
Team stats:
</SoftBox>


<Grid  justifyContent="space-between"  alignItems="center" p={1} >
    
{
                  (() => {
                  const listitems = this.state.teams_details;
                  console.log(listitems);
                  //console.log("populating")
                  if (typeof(listitems) != "undefined")
                  {
                  //console.log(listitems)
              const rows = [];
              for (let i = 0; i < listitems.length; i++) 
              {
                //team_name,  team_logo, current_wallet_balance, current_players_count, players
                  
                  rows.push(
            <Team max_players_count={this.state.main_auction_details.team_max_playes_count} max_wallet_size={this.state.main_auction_details.team_max_pocket_size} linktoteam={listitems[i].team_hash_code} players={listitems[i].players} max_bid_for_a_player={listitems[i].max_bid_for_a_player} current_players_count={listitems[i].current_players_count} description={listitems[i].description} current_wallet_balance={listitems[i].current_wallet_balance} team_logo={listitems[i].team_logo} team_name={listitems[i].team_name} key={i} />
            );
              }
              return rows;
            }

            })()
        
          }

    </Grid>

    </Grid>
          </Grid>
        </SoftBox>





        <SoftBox display="flex" alignItems="flex-start" p={1}>
            <Box sx={{display: 'flex', flexDirection: 'column', }} p={1}>
              <div>
                  Players list with stats:
                  Pending Players
                </div> 
                <div>
                  {
                    (() => {
                      const listitems = this.state.pending_players;
                      //  console.log(listitems);
                      // console.log("populating")
                      if (typeof (listitems) != "undefined") {
                        //console.log(listitems)
                        const rows = [];
                        for (let i = 0; i < listitems.length; i++) {
                          //team_name,  team_logo, current_wallet_balance, current_players_count, players

                          rows.push(<PendingPlayer user_id={listitems[i].user_id} player_name={listitems[i].full_name} sl_no={listitems[i].sl_no} profile_pic={listitems[i].profile_pic} auction_sold_status={listitems[i].auction_sold_status} cric_heroes_profile_link={listitems[i].cric_heroes_profile_link} key={i} />);
                        }
                        return <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>{rows}</SoftBox>;
                      }

                    })()

                  }
                </div>
            </Box>
            

            

            

          </SoftBox>

    </Card>
    <Footer />
    
    </DashboardLayout>
    );
  }
}
export default LiveAuction;