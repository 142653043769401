/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import axios from 'axios';  
import React from 'react';  
import { useLocation, Link } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
// @mui material components
import Card from "@mui/material/Card";
import SoftInput from "components/SoftInput";
import { Calendar } from 'primereact/calendar'; // Import Calendar for date selection


// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Helmet } from "react-helmet";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button as ButtonP }  from 'primereact/button';
import { InputText as pText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { Toolbar } from 'primereact/toolbar';
import { exportCSV } from 'primereact/utils';

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import {useEffect, useState, useRef} from 'react';
//import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import FormSelect from 'react-bootstrap/FormSelect';
import {getPlayerProfile} from 'Utils.js'
import gr_logo from "assets/images/group.png";

import jsPDF from 'jspdf';
import 'jspdf-autotable';

// Data
//import membersTableData from "layouts/groups/data/membersTableData";

import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { Navigate } from "react-router-dom";
import SoftButton from "components/SoftButton";
import Modal from 'react-modal';

import whatsapp_logo from "assets/images/logos/whatsapp.png";
import telegram_logo from "assets/images/logos/telegram.png";
import { FaMinusCircle } from "react-icons/fa";
import { FaPenSquare } from "react-icons/fa";
import { FaBackward } from "react-icons/fa";
import { FaForward } from "react-icons/fa";
import { FaImages } from "react-icons/fa";

import { alertTitleClasses } from '@mui/material';

import 'primereact/resources/themes/lara-light-indigo/theme.css';  
import 'primereact/resources/primereact.min.css';                  
import 'primeicons/primeicons.css';                                
import 'primeflex/primeflex.css';       

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {zIndex: 100000}   
};


function Author({ image, name, email }) {
  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column" >
        <SoftTypography variant="button" fontWeight="medium" color="info">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {email}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
};
function Function({ job, org }) {
  return (
    <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="caption" fontWeight="medium" color="text">
        {job}
      </SoftTypography>
      <SoftTypography variant="caption" color="secondary">
        {org}
      </SoftTypography>
    </SoftBox>
  );
};
let global_group_id='';
export default class Attendance extends React.Component {  
  constructor(props) {
    super(props);
    this.setState({loggedIn: '1'});
    this.setState({showModal: false,isChecked:false});

    const today = new Date();
    const threeMonthsBack = new Date();
    threeMonthsBack.setMonth(today.getMonth() - 3);
    
    
    window.helloComponent = this;

    
    this.state = { data: [],
      loading: true,loggedIn: '1',
      globalFilter: '',  // Search box filter
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
            },
            fromDate: threeMonthsBack,  // Default from date
            toDate: today ,
            first: 0,  // Track current page to calculate Sl No
            rows: 10 ,
            showModal: false,viewstatus:'list',linkrows:[],group_logo:team2,groupname:'Cricket Group',group_normal_count: 14, group_guest_count : 2};

    let search = window.location.search;
    let params = new URLSearchParams(search);

     global_group_id = params.get('id')
    if(global_group_id==null)
    {
      global_group_id=  '';
    }


    let sid = params.get('sid')
    if(sid==null)
    {
      sid=  '';
    }
    let series_id = '';
    if(sid!=''){
      series_id = atob(sid);
    }

    let csid = params.get('csid')
    if(csid==null)
    {
      csid=  '';
    }
    let cseries_id = '';
    if(csid!=''){
      cseries_id = atob(csid);
    }
    if(series_id  !='')
    {
     
      setTimeout(
        () => this.setState({viewstatus: 'createlink'}), 
        1500
      );
      //this.setState({viewstatus: 'createlink'});
    }else if(cseries_id !=  '')
    {
      setTimeout(
        () => this.setState({viewstatus: 'createlink'}), 
        1500
      );
    }
    this.dt = React.createRef();
      
//    this.setState({linkData:{rows:[]}});

  this.ref_link_heading = React.createRef();

  this.ref_link_heading_link = React.createRef();
    
  }
  state = {  
    normalmembers: []  ,
    guestmembers:[]

  }  

  showModal (group_fk,user_fk) {
    getPlayerProfile(group_fk,user_fk,'i_profile_modal');
    //call API i_profile_modal
    //alert(group_fk + ' ' + user_fk)
    this.setState({
      show: !this.state.show
    });
  };
  closeModal = e => {
    this.setState({
      show: false
    });
  
};
  
ondImageChange = event => {
  if (event.target.files && event.target.files[0]) {
    let img = event.target.files[0];
   // alert(img)

    const formData = new FormData();
  // alert(document.getElementById('dp_member_id').value)
    formData.append("profile_image", event.target.files[0]);
    formData.append("dp_member_id", this.state.selected_member);
    //call API
    console.log(formData)

    const pconfig = {     
      headers: { 'content-type': 'multipart/form-data' }
  }

    axios.post( global.config.app.backend_api_url + `members/uploadprofilepic_byadmin`,formData,pconfig, { withCredentials: true }
    )  
      .then(res => {  
        //const normalmembers = res.data;  
        console.log("after112")
        console.log(res.data)
        if(res.data.status=='0')
        {
          alert(res.data.message);
        }
        else
        {
          alert(res.data.message);
          //alert(res.data.profile_pic)
          /*
          this.setState({
            image: URL.createObjectURL(img)
          });
          */
          this.setState({ viewstatus: 'list'});

        }
  
      });

      

   
  }
};

clickedAddMember(val_id,name){
  // alert("Called from outside"+val_id);

   document.getElementById('j_member_id').value    = val_id;
   document.getElementById('member_name').value    = name;
   document.getElementById('member_name').readOnly = true;
   document.getElementById("member_name").style.backgroundColor = "#EBEBE4";


   
}


onKeyDownSearch = (e)=> {

  if(this.state.selected_member !='')
  {
    return;
  }
  const j_mobile_no = document.getElementById('mobile_no').value

  document.getElementById('j_member_id').value    = '';
  //this.ref_j_mobile_no.current  = j_mobile_no;

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let group_code = atob(params.get('id'));
  if(group_code==null)
{
  group_code  = '';
}

  const post_data = { mobile_no: j_mobile_no,group_id : group_code,from_add_to_group:1 };
    
//      alert(group_id)
//,{headers: {"Content-Type": "application/json"}}
  axios.post( global.config.app.backend_api_url + `members/search`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      console.log("after112")
      
      
      document.getElementById("j_members_list").innerHTML=  '';
      var html_content = '';
      if(res.data.status!='0')
      {
        console.log(res.data)
        //const normalmembers = this.createMemberRows(res.data);
        var iloop ;
        for(iloop=0; iloop < res.data.length; iloop++){
            const full_name = res.data[iloop].full_name;
            const mobile_no = res.data[iloop].mobile_no;
            var m_user_id = res.data[iloop].user_id;
            //console.log(full_name)

            

            //html_content = html_content + createsearchlist(full_name)
            html_content = html_content + (iloop+1) + '. '+ full_name +' ( '  + mobile_no + " ) <a href='javascript:void(0)' onclick='window.helloComponent.clickedAddMember(&#39;"+m_user_id+"&#39;,&#39;"+full_name+"&#39;)'>Select</a><br>";

        }

        document.getElementById("j_members_list").innerHTML=html_content;
        const links_columns1 = [{ name: "full_name", align: "left" },
        { name: "mobile_no", align: "left" }];

       

      }else
      {
        document.getElementById("j_members_list").innerHTML=  'No members found.';

      }
      


      

    });

  

//    alert('API to be called..'+this.ref_j_mobile_no.current);
}


onBacklist = (e)=> {
  this.callbasicapi();
  this.setState({viewstatus: 'list'});

}
checkIsDraft= (e)=> {
  
  if(document.getElementById('s_is_draft').value=="1")
  {
    document.getElementsByClassName("draft_item")[0].style.display='';
  }
  else
  {
    document.getElementsByClassName("draft_item")[0].style.display='none';
  }
}
onClickAddLink = (e)=> {

  var j_mobile_no = document.getElementById('mobile_no').value
  var j_member_id = '';

  var j_keep1_checked = document.getElementById('j_keep1').checked;

  var j_full_name = '';
  try{
    j_full_name = document.getElementById('member_name').value
    
  }catch(Err)
  {
  }

  try{
    j_member_id = document.getElementById('j_member_id').value        
  }catch(Err)
  {
  }
  var overall_user_rating = document.getElementById('overall_user_rating').value
  var overall_comments = document.getElementById('overall_comments').value
  var public_description_about_member = document.getElementById('public_description_about_member').value

  var cric_heroes_profile_link = document.getElementById('cric_heroes_profile_link').value
  var member_type = document.getElementById('member_type').value

 var group_admin= document.getElementById('group_admin').value


  let search = window.location.search;
  let params = new URLSearchParams(search);
  let group_fk = atob(params.get('id'));

  

  const post_data = { group_admin:group_admin, member_type: member_type,member_id : j_member_id,member_name:j_full_name,group_fk:group_fk,member_mobile_no:j_mobile_no,overall_user_rating:overall_user_rating,overall_comments:overall_comments,public_description_about_member:public_description_about_member,cric_heroes_profile_link:cric_heroes_profile_link };
    console.log(post_data)
//      alert(group_id)
//,{headers: {"Content-Type": "application/json"}}
  axios.post( global.config.app.backend_api_url + `groups/add_edit_member`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      console.log("after112")
      console.log(res.data)
      if(res.data.status=='0')
      {
        alert(res.data.message);
      }
      else
      {
        alert(res.data.message);
        document.getElementById('mobile_no').value  = '';

        try{
          document.getElementById('member_name').value  = '';
        }catch(Err)
        {
        }
        try{
          document.getElementById('j_member_id').value  = '';
        }catch(Err)
        {
        }
        document.getElementById('overall_user_rating').value  = '';
        document.getElementById('overall_comments').value  = '';
        document.getElementById('public_description_about_member').value  = '';
        document.getElementById('cric_heroes_profile_link').value  = '';
        
        if(!j_keep1_checked)
        {
          this.callbasicapi();
          this.setState({viewstatus: 'list'});
        }
      }

    });



// alert('API to be called');
}

deleteMemberRow(mid, e){  

  if(window.confirm('Sure?') ==  false)
  {
    return;
  }
  //alert("deleted id : "+ id);
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let group_id = atob(params.get('id'));

  console.log( this.state.normalmembers);
  //const normalmembers = this.state.normalmembers.filter(item => item.id !== id);  
  //this.setState({ normalmembers }); 
  
  const post_data = { group_fk: group_id,member_id:mid };
   
  axios.post( global.config.app.backend_api_url + `groups/remove_member`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data; 
      if(res.data.status=='0')
      {
        alert(res.data.message);
      } 
      else{
        alert(res.data.message);
        this.callbasicapi();
        this.setState({viewstatus: 'list'});
        
      }


      

    });


  
}  

callNotificationSubscribe(appid,userid)
{
  if(userid=='' || appid=='')
  {
    return;
  }
  const s = document.createElement("script")
  s.src = "https://notix.io/ent/current/enot.min.js"
  s.onload = (sdk) => {
      sdk.startInstall({
          appId: appid,
          loadSettings: true,
          disableConsoleDebug: true,
          user: userid
      });

  }
  document.head.append(s);
}

onGlobalFilterChange = (e) => {
  const value = e.target.value;  // Get the typed value
  this.setState({
      globalFilter: value,  // Update the state
      filters: { 'global': { value, matchMode: FilterMatchMode.CONTAINS } }
  });
};

// Export Data as CSV
exportCSV = () => {
  this.dt.current.exportCSV();
};

exportPDF = () => {
  const doc = new jsPDF();
  const exportData = this.state.filteredData || this.state.data;

  doc.autoTable({
      head: [['Sl No', 'Name', 'Mobile No', 'No.of Matches', 'Type', 'Last Match', 'First Match', 'Days Gap']],  // Table header
      body: exportData.map((item ,index) => [index+1, item.full_name,item.mobile_no, item.no_of_times, item.member, item.last_match_date, item.first_match_date, item.days_gap]),  // Table body
  });
  doc.save('attendance-data.pdf');  // Save the PDF
};

noOfTimesTemplate = (rowData) => {
  if(rowData.member === 'Guest')
  {

  
  const style = (rowData.member === 'Guest' && rowData.no_of_times >= 3) 
      ? { backgroundColor: '#0ced6a', color: '#cc0000', fontWeight: 'bold' } // Custom styling
      : {};

  return (
      <span style={style}>
          {rowData.no_of_times}
      </span>
  );
  }else
  {
    const style = (rowData.member === 'Normal' && rowData.no_of_times == 0) 
      ? { color: '#cc0000', fontWeight: 'bold' } // Custom styling
      : {};

  return (
      <span style={style}>
          {rowData.no_of_times}
      </span>
  );
  }
};

last_match_date_pattern = (rowData) => {
  
    if(rowData.last_match_date === '1900-01-01')
    {
      rowData.last_match_date = '';
    }

  return (
      <span >
          {rowData.last_match_date}
      </span>
  );
};

first_match_date_pattern = (rowData) => {
  
  if(rowData.first_match_date === '1900-01-01')
  {
    rowData.first_match_date = '';
  }

return (
    <span >
        {rowData.first_match_date}
    </span>
);
};


toolbarTemplate = () => (
  <div className="p-d-flex p-jc-between">

    {/* Date Input Section */}
    <div className="p-d-flex p-jc-between p-ai-center p-mb-4 p-flex-wrap">
                    
    <div className="p-mr-2">
                        <label htmlFor="fromDate">From Date:</label>
                        <Calendar 
                            id="fromDate" 
                            value={this.state.fromDate} 
                            onChange={(e) => this.setState({ fromDate: e.value })} 
                            dateFormat="dd/mm/yy" 
                            showIcon 
                        />
                    </div>
                    <div className="p-mr-2">
                        <label htmlFor="toDate">To Date:</label>
                        <Calendar 
                            id="toDate" 
                            value={this.state.toDate} 
                            onChange={(e) => this.setState({ toDate: e.value })} 
                            dateFormat="dd/mm/yy" 
                            showIcon 
                        />
                    </div>
                    <div className="mb-4">
                    <ButtonP 
                        label="Retrieve" 
                        icon="pi pi-check" 
                        className="p-button-primary" 
                        onClick={this.handleRetrieve} 
                    />
                    </div>
                </div>

      <div>
          <ButtonP
              label="Export CSV" 
              icon="pi pi-file" 
              className="p-button-success p-mr-2" 
              onClick={this.exportCSV} 
          />

<ButtonP
                        label="Export PDF"
                        icon="pi pi-file-pdf"
                        className="p-button-danger"
                        onClick={this.exportPDF}
                    />

      </div>
  </div>
);

  componentDidMount() { 
      /*
    axios.get(global.config.app.backend_api_url +`groups/setsession?user_id=2`, { withCredentials: true })//, { withCredentials: true }  
      .then(res => {  

      });*/
      const post_data1 = { lo : 1};

      axios.post( global.config.app.backend_api_url + `login/sessionavailable`,post_data1, { withCredentials: true }
      )  
        .then(res => {  
          console.log(res.data)
          var user_id = res.data.user_details.user_id;
          
          if (user_id !== undefined && user_id !== null && user_id !== 'null') 
          {

            
          }else
          {
            window.location.replace('sign-in');
            //return;
          }

          if(res.data.user_details.user_id!='')
          {
           // this.callNotificationSubscribe(res.data.user_details.nai,res.data.user_details.user_id);
          }
    
        });

       

        
       // this.callbasicapi();
        this.fetchData(this.state.fromDate, this.state.toDate);
        
      
  }  

  handleRetrieve = () => {
    // Call fetchData with the selected dates when Retrieve is clicked
    this.fetchData(this.state.fromDate, this.state.toDate);
};

onPageChange = (event) => {
  this.setState({ first: event.first, rows: event.rows });
};

onFilter = (e) => {
  this.setState({ filteredData: e.filteredValue });
};



slNoTemplate = (rowData, options) => {
  const { first, rows } = this.state;
  return <span>{first + options.rowIndex + 1}</span>;
};


  fetchData = (fromDate, toDate) => {
    // Format the dates to be used in the API call (if required)
    const from = fromDate.toISOString().split('T')[0]; // 'YYYY-MM-DD'
    const to = toDate.toISOString().split('T')[0]; // 'YYYY-MM-DD'
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let group_id = atob(params.get('id'));
    
    const post_data = { from_date: from,to_date:to ,group_id:group_id};

    console.log('Fetching data from:', from, 'to:', to);
    this.setState({ loading: true }); // Show loader

    // Replace this with your axios.get logic using fromDate and toDate
    
    axios.post( global.config.app.backend_api_url + `groups/getattendance`,post_data, { withCredentials: true }
    )  
        .then(response => {
          if(response.data.status==='0')
          {
            alert(response.data.message);
            this.setState({ loading: false }); // Show loader

            return;
          }
            this.setState({ data: response.data.attendance_data,loading:false });
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
};



  callbasicapi()
  {
    let search = window.location.search;
      let params = new URLSearchParams(search);
      let group_id = atob(params.get('id'));



    
      const post_data = { group_id: group_id };
      
    // alert(group_id)
//,{headers: {"Content-Type": "application/json"}}
    axios.post( global.config.app.backend_api_url + `groups/getbyid`,post_data, { withCredentials: true }
    )  
      .then(res => {  
        //const normalmembers = res.data;  
        console.log("after112d")
        console.log(res.data.group_data)
//        alert(res.data.group_data[0].is_admin)
        this.setState( {group_logo: res.data.group_data[0].group_logo,groupname:res.data.group_data[0].groupname,group_normal_count: res.data.group_data[0].normal_members_count, group_guest_count : res.data.group_data[0].guest_members_count,group_admin_flag : res.data.group_data[0].is_admin});

        const normalmembers = this.createMemberRows(res.data.normal_members);
        console.log("getting response "+res.data.normal_members)
        //this.setState({ normalmembers });  

        const guestmembers = this.createMemberRows(res.data.guest_members);
        console.log("getting response ")
        this.setState({ normalmembers,guestmembers,series_notification_type: res.data.series_notification_type});  

       // series_notification_type


        

      });
      /*
      axios.get(global.config.app.backend_api_url +`testAPIp`, { withCredentials: true })  
      .then(res => {  
        this.setState({loggedIn: res.data.loggedIn, designation: "your value"});

      });*/

  }
    
 onClickGenerateLink = (e)=> {

  
  var s_series_name = document.getElementById('s_series_name').value
  var s_match_date = document.getElementById('s_match_date').value
  var s_from_time = document.getElementById('s_from_time').value
  var s_to_time = document.getElementById('s_to_time').value
  var s_header_description = document.getElementById('s_header_description').value
  var s_footer_description = document.getElementById('s_footer_description').value
  var s_total_participants = document.getElementById('s_total_participants').value
  var s_total_guests_participants= document.getElementById('s_total_guests_participants').value

  var s_total_waiting_list = document.getElementById('s_total_waiting_list').value
  var s_add_delete_permission_cutoff_mins = document.getElementById('s_add_delete_permission_cutoff_mins').value
  var show_join_link_group_members_only= document.getElementById('show_join_link_group_members_only').value;

  var enable_browser_push= document.getElementById('enable_browser_push').value;
  var enable_email_push= document.getElementById('enable_email_push').value;
  var enable_telegram_push= document.getElementById('enable_telegram_push').value;
  var enable_whatsapp_push= document.getElementById('enable_whatsapp_push').value;


  var s_group_id = document.getElementById('s_group_id').value
  var s_series_id = document.getElementById('s_series_id').value
  
  var s_notify= 0;

  if(document.getElementById('s_notify').checked)
  {
    s_notify  = 1;
  }

  var s_is_draft= document.getElementById('s_is_draft').value;
  var s_notify_automatically= document.getElementById('s_notify_automatically').value;
  var photo_upload= document.getElementById('photo_upload').value;

  var s_draft_publish_date  = document.getElementById('s_draft_publish_date').value
  var s_draft_publish_time  = document.getElementById('s_draft_publish_time').value
  var mi_manual_sl_seq= document.getElementById('mi_manual_sl_seq').value

  var status_fk= document.getElementById('s_status_fk').value
  
  const post_data = {series_id : s_series_id, series_name: s_series_name,match_date : s_match_date,from_time:s_from_time,to_time:s_to_time 
    ,header_description:s_header_description ,footer_description:s_footer_description ,total_participants:s_total_participants ,
    total_waiting_list:s_total_waiting_list 
    ,add_delete_permission_cutoff_mins:s_add_delete_permission_cutoff_mins ,group_id:s_group_id, notify:s_notify,show_join_link_group_members_only:show_join_link_group_members_only
    ,enable_browser_push:enable_browser_push,enable_whatsapp_push:enable_whatsapp_push,enable_telegram_push:enable_telegram_push,
    enable_email_push:enable_email_push,s_is_draft:s_is_draft,s_draft_publish_date:s_draft_publish_date,
    s_draft_publish_time:s_draft_publish_time,status_fk:status_fk ,notify_automatically:s_notify_automatically
  ,total_guests_participants:s_total_guests_participants,mi_manual_sl_seq:mi_manual_sl_seq,
  photo_upload:photo_upload};
    console.log(post_data)
//      alert(group_id)
//,{headers: {"Content-Type": "application/json"}}
  axios.post( global.config.app.backend_api_url + `matchschedule/generatelink`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      console.log("after112")
      console.log(res.data)
      if(res.data.status=='0')
      {
        alert(res.data.message);
      }
      else
      {
        alert(res.data.message + ' Link is '+res.data.invite_link);
        this.setState({viewstatus: 'list'});
      }

    });


  }


    

  deleteRow(id, e){  
    console.log("deleted id : "+ id);
    console.log( this.state.normalmembers);
    const normalmembers = this.state.normalmembers.filter(item => item.id !== id);  
    this.setState({ normalmembers }); 
    /*
    axios.delete(`http://localhost:9000/groups/${id}`)  
      .then(res => {  
        console.log(res);  
        console.log(res.data);  
    
        const normalmembers = this.state.normalmembers.filter(item => item.id !== id);  
        this.setState({ normalmembers });  
      })  */
    
  }  

  membersTableColumns = {
    member_columns : [
      { name: "slno",align: "left" },
      { name: "member_name",align: "left" },
      { name: "mobile_no",align: "left" },
      { name: "added_on",align: "left" },
      { name: "action",align: "left" },
    ]
  }
  
  linkTableColumns = {
    links_columns: [
      { name: "invitelink", align: "left" },
      { name: "max_join_limit_with_link", align: "left" },
      { name: "current_join_count", align: "left" },
      { name: "link_expiry", align: "left" },
      { name: "action", align: "left" },
    ]
  };

  linkTableRows = {
    linkrows: [
      {
        invitelink: <Author image={team2} name="https://cricketgroups.in/sdfdsf"  />,
        max_join_limit_with_link: <Function job="10"  />,
        current_join_count: <Function job="1"  />,
        link_expiry: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            23/12/2023
          </SoftTypography>
        ),
        action: (
          <SoftBox>
          <SoftTypography
            component="a"
            href="javascript:void(0)"
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            Delete
          </SoftTypography>
          <SoftBox component="img" src={whatsapp_logo} alt="Share to whatsapp" width="24px" mt={1} />
          <SoftBox component="img" src={telegram_logo} alt="Share to telegram" width="24px" mt={1} />

</SoftBox>

        ),
      }
      
    ],
  };


   membersTableData = {
    columns: [
      { name: "slno", align: "left" },
      { name: "member_name", align: "left" },
      { name: "added_on", align: "left" },
      { name: "action", align: "left" },
    ],
  
    rows: [
      {
        slno : <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        1
      </SoftTypography>,
        member_name: <Author image={team2} name="Friends Group"  />,
        location: <Function job="Trivandrum"  />,
        memberscount: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            25
          </SoftTypography>
        ),
        added_on: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            12/11/2022
          </SoftTypography>
        ),
        action: (
          <SoftBox>
          <SoftTypography
            component="a"
            href="javascript:void(0)"
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            Delete
          </SoftTypography>
</SoftBox>

        ),
      }
      
    ],
  };

  createMemberRows(res)
{
  //loop here and create rows in soft UI rows
  console.log(res)
  const plainObj = [];

  var iloop ;
  for(iloop=0; iloop < res.length; iloop++){
      const thisid = res[iloop].id;
      const href_link = "/viewmembers/"+thisid;
      const g_group_fk  = res[iloop].group_id;
      const g_user_fk  = res[iloop].user_fk;
      const type_name  = res[iloop].type;
      const type_fk  = res[iloop].member_type_id;
      const is_admin_flag  = res[iloop].is_admin;

      const group_admin  = res[iloop].group_admin;
      const selected_member_name = res[iloop].member_name;
      const allow_pic_upload_by_gr_admin= res[iloop].allow_pic_upload_by_gr_admin;
      //loopData += `<li>${data[i].name}</li>`
      const arrayLike = {
        slno: <Function job={iloop+1}  />,
        member_name: (
        <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar src={res[iloop].profile_pic ? (res[iloop].profile_pic):(team2)} alt={res[iloop].member_name} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column" >
        <SoftTypography component="a" href='javascript:void(0)'  onClick={() => this.showModal(g_group_fk,g_user_fk)} variant="button" fontWeight="medium" color="info">
        {res[iloop].member_name}
        </SoftTypography>
        
      </SoftBox>
    </SoftBox>
),
mobile_no: <Function job={res[iloop].mobile_no}  />,
        location: <Function job={res[iloop].location}  />,
        memberscount: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            25
          </SoftTypography>
        ),
        'added_on': res[iloop].added_on,
        'action' : (
          <SoftBox>
            {/*
          <SoftTypography
            component="a"
            href="#"
            variant="caption"
            color="secondary"
            fontWeight="medium"
            onClick={() => this.deleteRow(thisid)}
          >
            Delete
          </SoftTypography>
          <SoftBox component="img" src={whatsapp_logo} alt="Share to whatsapp" width="24px" mt={1} />


          

            */
            
            }

          {
                (() =>{
            if(is_admin_flag  =='1')//this.state.group_admin_flag
            {
              return(
            <SoftTypography
            component="a"
            href="javascript:void(0)"
            variant="caption"
            color="secondary"
            title="Edit"
            fontWeight="medium"
            onClick={() => this.setState({viewstatus: 'create',member_type:type_fk,member_type_name:type_name,selected_member:g_user_fk,group_admin_type:group_admin})}
          >
        <FaPenSquare></FaPenSquare>

            
          </SoftTypography>
              )
              
            }
            })()
        
                
          }
           &nbsp;&nbsp;&nbsp;

{
                (() =>{
            if(is_admin_flag  =='1' && allow_pic_upload_by_gr_admin=='1')//this.state.group_admin_flag
            {
              return(
            <SoftTypography
            component="a"
            href="javascript:void(0)"
            variant="caption"
            color="secondary"
            title="Upload Pic"
            fontWeight="medium"
            onClick={() => this.setState({viewstatus: 'uploaduserdp',allow_pic_upload_by_gr_admin:allow_pic_upload_by_gr_admin,member_type:type_fk,member_type_name:type_name,selected_member:g_user_fk,group_admin_type:group_admin,selected_member_name:selected_member_name})}
          >
        <FaImages></FaImages>

            
          </SoftTypography>
              )
              
            }
            })()
        
                
          }
          
          &nbsp;&nbsp;&nbsp;

          {
                (() =>{
            if(is_admin_flag  =='1')//this.state.group_admin_flag
            {
              return(
          <SoftTypography
          component="a"
          title="Remove?"
          href="javascript:void(0)"
          variant="caption"
          color="secondary"
          fontWeight="medium"
          onClick={() => this.deleteMemberRow(g_user_fk)}
        >
         <FaMinusCircle></FaMinusCircle>
        </SoftTypography>
)
              
}
})()

    
}
          

          </SoftBox>
          
        ),
        'id' : res[iloop].id
      };
      Array.prototype.push.call(plainObj, arrayLike);

  }
  
    //const myObjStr = JSON.stringify(plainObj);
    console.log("obj:")
    console.log(plainObj)
    console.log("res:")
    console.log(res)
    
    return plainObj;
  }

  
  


  render() {  

    const handleClose = () => {
      this.setState({showModal: false});
    }
    const handleShow = () => {
      this.setState({showModal: true});
    }

    if(this.state.loggedIn!=='1')
    {
      return <Navigate to="/sign-in" />
  
    };

  const { columns, rows } = this.membersTableData;

  const {links_columns} = this.linkTableColumns;

  const {linkrows} = this.linkTableRows;
  const {member_columns} = this.membersTableColumns;

  const { fromDate, toDate } = this.state;  // Destructure state data


  //const [list, setList] = useState([]);
  //const [actuallist, setActuallist] = useState([]);

    console.log("sess_data:");  
    console.log(this.state.loggedIn);  

    let link_to_schedules = "/schedules";

    let search1 = window.location.search;
    let params1 = new URLSearchParams(search1);
    let group_id1 = atob(params1.get('id'));
    
    let querystring1 = "?g="+btoa(group_id1);

    const { data, loading, filters } = this.state;

    return (  
      <DashboardLayout>
      <Helmet>
      <title>Group Members | {global.config.app.name}</title>
      <meta
        name="description"
    content="Join Our Cricket Team - Experience the thrill of competitive cricket and be part of a supportive community. Sign up now and showcase your skills on the field."
      />
      <meta
        name="keywords"
        content="Cricket, team, join, competitive, skills, community, sign up, field"
      />
    </Helmet>
      <DashboardNavbar />
      <SoftBox py={3}>
      <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>

            <SoftAvatar
              src={this.state.group_logo ? (this.state.group_logo):(gr_logo)}
              alt="profile-image"
              variant="rounded"
              size="xl"
              shadow="sm"
            />

              {this.state.groupname} |&nbsp;&nbsp;&nbsp;&nbsp;
              {this.state.group_normal_count} Nomral Members |&nbsp;&nbsp;&nbsp;&nbsp;
              {this.state.group_guest_count} Guest Members

              <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              {
                (() => {
                  let search = window.location.search;
                    let params = new URLSearchParams(search);
                    let group_id = atob(params.get('id'));

                    

                  if(this.state.group_admin_flag == '1' ){ 

                 // var link_to_create = "/creatematchlink?group_id="+btoa(group_id);


                  let search = window.location.search;
                  let params = new URLSearchParams(search);
                  let series_id ='';
                  let c_series_id ='';
                  if(params.get('sid')!=null)
                  {
                    series_id = atob(params.get('sid'));
                  }
                  if(params.get('csid')!=null)
                  {
                    c_series_id = atob(params.get('csid'));
                  }

                  
                  
                  var caption_cl = 'Generate new match schedule link';
                  if(series_id!='')
                  {
                    caption_cl = 'Edit match link';
                  }/*else if(c_series_id!='')
                  {
                    caption_cl = 'Copy and generate a new match link';
                  }*/
                  
                  


                   return (

      
                    <SoftButton variant="gradient" size="small" color="info"  onClick={() => this.setState({viewstatus: 'createlink'})}>
                    {caption_cl}
              </SoftButton>
                    
                   )
                   }
                  })()
        
                }
             
             
              </SoftBox>

              </SoftBox>
              
              

      </Card>
      <SoftBox py={3}>
      
      <Link to="/groups">
                
                <SoftTypography
                  variant="button"
                  fontWeight="medium"
                  color="dark"
                  onClick={() => this.setState({viewstatus: 'list'})}
                >
                  <FaBackward></FaBackward> My Groups
                </SoftTypography>
              
            </Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link to={{pathname:link_to_schedules,search:querystring1} }>
                
                <SoftTypography
                  variant="button"
                  fontWeight="medium"
                  color="dark"
                  
                >
                  <FaForward></FaForward> Schedules
                </SoftTypography>
              
            </Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link to={{pathname:link_to_schedules,search:querystring1} }>
                
                <SoftTypography
                  variant="button"
                  fontWeight="medium"
                  color="dark"
                  
                >
                  <FaForward></FaForward> Attendance
                </SoftTypography>
              
            </Link>
      </SoftBox>
        <SoftBox mb={3}>
        <Toolbar className="p-mb-4" left={this.toolbarTemplate}></Toolbar>

        {/* Loader Section */}
        {this.state.loading ? (
                    <div className="p-d-flex p-jc-center p-mt-4">
                        <ProgressSpinner />
                    </div>
                ) : (
                  <>
                  

                  <DataTable 
                            value={data} 
                            paginator 
                            stripedRows
                            scrollable
                            rows={10} 
                            first={this.state.first} 
                            filterDisplay="row" 
                            onPage={this.onPageChange}
                            //onFilter={this.onFilter}
                            responsiveLayout="scroll" 
                            ref={this.dt}
                            showGridlines 
                            tableStyle={{ minWidth: '50rem' }}
                        >

<Column 
                        header="Sl No" 
                        field="sl"
                        body={this.slNoTemplate} 
                        style={{ width: '80px' }} 
                    />

                            <Column frozen field="full_name" header="Name" sortable filter filterPlaceholder="Search by Name" ></Column>
                            <Column  frozen field="mobile_no" header="Mobile No" sortable filter filterPlaceholder="Search by Mobile" ></Column>
                            <Column field="no_of_times" header="No.of Matches" sortable filter filterPlaceholder="Search by Matches" body={this.noOfTimesTemplate} ></Column>
                            <Column field="member" header="Type" filter filterPlaceholder="Search by Type" ></Column>
                            <Column field="last_match_date" header="Last Match" sortable body={this.last_match_date_pattern}  ></Column>
                            <Column field="first_match_date" header="First Match" sortable body={this.first_match_date_pattern} ></Column>
                            <Column field="days_gap" header="Days Gap" sortable ></Column>
                            
                        </DataTable>

                    </>
                )}


        {
                (() => {
                  if(this.state.viewstatus === 'list' ){ 
                   return (
          <></>
        )}
        
        else if(this.state.viewstatus === 'uploaduserdp' )
        {
        }

        else if(this.state.viewstatus === 'edit' )
        { 
        }

        else if(this.state.viewstatus === 'invite_link' )
        {
        }

        
      })()
        
        }
        </SoftBox>
        
      </SoftBox>
      
      <Modal
        isOpen={this.state.show}
        onRequestClose={this.closeModal}
        style={customStyles}
        contentLabel="Modal"
      >
        <h2 >Profile</h2>
        
        <button variant="button" fontWeight="medium" color="info" onClick={this.closeModal}>close</button>
        <div id='i_profile_modal'>Player profile </div>
        
      </Modal>

      <Footer />
      
    </DashboardLayout>
    )  
  }  
}  

